.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #070E27;
}
.container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 13.6vh;
  display: flex;
  align-items: center;
  border-color: rgba(78, 90, 133, 0.2);
  border-width: 0px;
  padding-left: 5.625vw;
  padding-right: 5.625vw;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.image {
  cursor: pointer;
  height: 4.18vh;
  object-fit: cover;
}
.Button {
  color: #ffffff;
  cursor: pointer;
  font-size: 1.12vw;
  font-style: normal;
  transition: 0.3s;
  font-family: Sora;
  font-weight: 300;
  padding-top: 2.09vh;
  border-width: 0px;
  padding-left: 3.137vh;
  border-radius: 8px;
  padding-right: 3.137vh;
  padding-bottom: 2.09vh;
  background-color: #162044;
}
.Button:hover {
  background-color: #0059F7;
}
.text {
  font-style: normal;
  font-weight: 400;
}
.container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 8.366vh;
  padding-left: 13.36vw;
  padding-right: 13.36vw;
  flex-direction: row;
  padding-bottom: 9.411vh;
  justify-content: space-between;
}
.text01 {
  color: #ffffff;
  font-size: 8vh;
  font-style: normal;
  font-family: Sora;
  font-weight: 700;
  line-height: 10.4vh;
}
.container3 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.text07 {
  color: #7E8BB6;
  font-size: 2.5vh;
  font-style: normal;
  font-family: Sora;
  font-weight: 400;
  line-height: 4.183vh;
  margin-bottom: 4.183vh;
}
.Button1 {
  color: #ffffff;
  cursor: pointer;
  font-size: 1.12vw;
  font-style: normal;
  transition: 0.3s;
  font-family: Sora;
  font-weight: 300;
  padding-top: 2.4vh;
  border-width: 0px;
  padding-left: 3.137vh;
  border-radius: 8px;
  padding-right: 3.137vh;
  padding-bottom: 2.4vh;
  background-color: #0059F7;
}
.Button1:hover {
  color: #070e27;
  background-color: #ffffff;
}
.text13 {
  font-style: normal;
  font-weight: 500;
}
.image1 {
  width: 90.014vw;
  object-fit: cover;
}
.container4 {
  flex: 0 0 auto;
  width: 73.62%;
  display: flex;
  align-items: center;
  padding-top: 8.366vh;
  border-color: rgba(78, 90, 133, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 8.366vh;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.image2 {
  height: 12.549vh;
  object-fit: cover;
}
.image3 {
  height: 12.549vh;
  object-fit: cover;
  margin-right: 2.812vw;
}
.image4 {
  height: 12.549vh;
  object-fit: cover;
  margin-right: 2.812vw;
}
.image5 {
  height: 12.549vh;
  object-fit: cover;
  margin-right: 2.812vw;
}
.image6 {
  height: 12.549vh;
  object-fit: cover;
}
.container5 {
  flex: 0 0 auto;
  width: 58.4%;
  height: 100px;
  display: flex;
  margin-top: 12.549vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.text14 {
  color: #ffffff;
  font-size: 6.274vh;
  font-style: normal;
  font-family: Sora;
  font-weight: 600;
}
.text16 {
  color: #7E8BB6;
  font-size: 2.5vh;
  font-style: normal;
  margin-top: 2.091vh;
  font-family: Sora;
  font-weight: 400;
  line-height: 4.183vh;
}
.container6 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
